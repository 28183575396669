import React, { useRef, useState, useEffect } from "react";
import { useGLTF, TransformControls } from "@react-three/drei";
import { proxy, useProxy } from "valtio";

import Band from './applicaseParts/Band'
import Body from './applicaseParts/Body'
import BottomHinges from "./applicaseParts/BottomHinges";
import Clasps from './applicaseParts/Clasps'
import CornerProtection from './applicaseParts/CornerProtection'
import FabricTape from './applicaseParts/FabricTape'
import Handle from './applicaseParts/Handle'
import { useFrame } from "react-three-fiber";

const Applicase = ({ state, scale = [1, 1, 1], position=[0, 0, 0],rotation, withCorners, withFeet, withHandle, ...otherProps }) => {
  const ref = useRef()
  const transform = useRef()
  const snap = useProxy(state)
  const { nodes, materials } = useGLTF('/appli2.glb')
  // Cursor showing current color
  const [hovered, setHovered] = useState(null)
  useEffect(() => {
    const cursor = `<svg width="64" height="64" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0)"><path fill="rgba(255, 255, 255, 0.5)" d="M29.5 54C43.031 54 54 43.031 54 29.5S43.031 5 29.5 5 5 15.969 5 29.5 15.969 54 29.5 54z" stroke="#000"/><g filter="url(#filter0_d)"><path d="M29.5 47C39.165 47 47 39.165 47 29.5S39.165 12 29.5 12 12 19.835 12 29.5 19.835 47 29.5 47z" fill="${snap.items[hovered]}"/></g><path d="M2 2l11 2.947L4.947 13 2 2z" fill="#000"/><text fill="#000" style="white-space:pre" font-family="Inter var, sans-serif" font-size="10" letter-spacing="-.01em"><tspan x="35" y="63">${hovered}</tspan></text></g><defs><clipPath id="clip0"><path fill="#fff" d="M0 0h64v64H0z"/></clipPath><filter id="filter0_d" x="6" y="8" width="47" height="47" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dy="2"/><feGaussianBlur stdDeviation="3"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/><feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/></filter></defs></svg>`
    const auto = `<svg width="64" height="64" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="rgba(255, 255, 255, 0.5)" d="M29.5 54C43.031 54 54 43.031 54 29.5S43.031 5 29.5 5 5 15.969 5 29.5 15.969 54 29.5 54z" stroke="#000"/><path d="M2 2l11 2.947L4.947 13 2 2z" fill="#000"/></svg>`
    document.body.style.cursor = `url('data:image/svg+xml;base64,${btoa(hovered ? cursor : auto)}'), auto`
  }, [hovered])

  useFrame(() => {
    ref.current.rotation.y += 0.004;
  })

  return (
      <group
        position={position}
        scale={scale}
        rotation={[0, -Math.PI / 4, 0]}
        ref={ref}
        {...otherProps}
        dispose={null}
        onPointerOver={(e) => (e.stopPropagation(), setHovered(e.object.material.name))}
        onPointerOut={(e) => e.intersections.length === 0 && setHovered(null)}
        onPointerMissed={() => (state.current = null)}
        onPointerDown={(e) => (e.stopPropagation(), (state.current = 'body'))}>
        <Band materials={materials} nodes={nodes} state={state} visible={true} />

        <Body materials={materials} nodes={nodes} state={state} visible={true} />

        <BottomHinges materials={materials} nodes={nodes} state={state} visible={true} />

        <Clasps materials={materials} nodes={nodes} visible={true} state={state} />

        <Handle materials={materials} nodes={nodes} visible={withHandle} state={state} />

        <FabricTape materials={materials} nodes={nodes} visible={true} state={state} />

        <CornerProtection materials={materials} nodes={nodes} state={state} withCorners={withCorners} withFeet={withFeet} />
      </group>
  )
}

export default Applicase
