import React from 'react'

const Band = ({materials, nodes, position=[0,0,0], visible=true, ...otherProps}) => {
	return (
		<mesh
			receiveShadow
			castShadow
			visible={visible}
			position={position}
			material={materials["black-glossy"]}
			geometry={nodes.Rahmen_UT.geometry}
		/>
  );
}

export default Band
