import React from 'react'

const CornerProtection = ({state,materials,nodes,position=[0,0,0],withCorners=true,withFeet=true,otherProps}) => {
	return (
    <group>
      {/* Nieten Stoßecken */}
      <mesh
        receiveShadow
        castShadow
        visible={withCorners}
        position={position}
        material={materials.iron}
        geometry={nodes["451098004"].geometry}
      />
      {/* Stoßecken */}
      <mesh
        receiveShadow
        castShadow
        visible={withCorners}
        position={position}
        material={materials["plastic.black.veryrough"]}
        geometry={nodes["400421001"].geometry}
      />
      {/* Fuesse */}
      <mesh
        receiveShadow
        castShadow
        visible={withFeet}
        position={withCorners ? position : [0,0.007,0]}
        material={materials["plastic.black.veryrough"]}
        geometry={nodes["400445_3"].geometry}
      />
    </group>
  );
}

export default CornerProtection
