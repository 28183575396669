import React from 'react'
import { HexColorPicker } from "react-colorful";
import { proxy, useProxy } from "valtio";
import "react-colorful/dist/index.css";

const Picker = ({state, pickerActive}) => {
  const [color, setColor] = React.useState('#ffffff');
  const snap = useProxy(state);
  const presetColors = [
    "#292826",
    "#454a4e",
    "#8f8f8d",
    "#cdcdca",
    "#e4aa16",
    "#e84a00",
    "#cc3129",
    "#5a5948",
    "#223975",
  ];
  const changeColor = (color) => {
    setColor(color);
    state.items.body = color
  };

  return (
    <div style={{ display: pickerActive ? "block" : "none" }}>
      <HexColorPicker
        className="picker"
        color={color}
        onChange={(color) => changeColor(color)}
      />
      <div className="picker__swatches">
        {presetColors.map((presetColor) => (
          <button
            key={presetColor}
            className="picker__swatch"
            style={{ background: presetColor }}
            onClick={() => changeColor(presetColor)}
          />
        ))}
      </div>
      <h3>Behälterfarbe</h3>
    </div>
  );
}

export default Picker
