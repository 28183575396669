import * as THREE from 'three'
import React, { Suspense, useEffect, useState } from 'react'
import { Canvas, useFrame } from 'react-three-fiber'
import { Reflector, Text, useTexture, useGLTF, Environment, TransformControls } from '@react-three/drei'

import { proxy, useProxy } from 'valtio'
import Picker from './components/Picker'
import Applicase from './models/Applicase'

const state = proxy({
  current: null,
  items: {
    body: '#e4aa16'
  }
})

function VideoText({ clicked, ...props }) {
  const [video] = useState(() => Object.assign(document.createElement('video'), { src: '/abstract_striped_bars.mp4', crossOrigin: 'Anonymous', loop: true,muted: true }))
  video.play();
  return (
    <Text font="/Inter-Bold.woff" fontSize={1.4} letterSpacing={-0.06} {...props}>
      Applicase
      <meshBasicMaterial toneMapped={false}>
        <videoTexture attach="map" args={[video]} encoding={THREE.sRGBEncoding} />
      </meshBasicMaterial>
    </Text>
  )
}

function Ground() {
  const [floor, normal] = useTexture(['/SurfaceImperfections003_1K_var1.jpg', '/SurfaceImperfections003_1K_Normal.jpg'])
  return (
    <Reflector resolution={512} args={[20, 20]} mirror={0.5} mixBlur={10} mixStrength={0.8} rotation={[-Math.PI / 2, 0, Math.PI / 2]} blur={[400, 100]}>
      {(Material, props) => <Material color="#a0a0a0" metalness={0.5} roughnessMap={floor} normalMap={normal} normalScale={[1, 1]} {...props} />}
    </Reflector>
  )
}

function Intro({ start, set }) {
  const [vec] = useState(() => new THREE.Vector3())
  useEffect(() => setTimeout(() => set(true), 500), [])
  return useFrame((state) => {
    if (start) {
      state.camera.position.lerp(vec.set(state.mouse.x * 5, 3 + state.mouse.y * 2, 14), 0.05)
      state.camera.lookAt(0, 0, 0)
    }
  })
}

export default function App() {
  const [clicked, setClicked] = useState(false)
  const [ready, setReady] = useState(false)
  const store = { clicked, setClicked, ready, setReady }

  const [withCorners, setWithCorners] = React.useState(true)
  const [withFeet, setWithFeet] = React.useState(true)
  const [withHandle, setWithHandle] = React.useState(true)
  const [pickerActive, setPickerActive] = React.useState(false)

  const transform = React.useRef()

  const toggleCorners = (e) => {
    e.preventDefault()
    setWithCorners(!withCorners)
  }
  const toggleFeet = (e) => {
    e.preventDefault()
    setWithFeet(!withFeet)
  }
  const toggleHandle = (e) => {
    e.preventDefault()
    setWithHandle(!withHandle)
  }
  const togglePicker = (e) => {
    e.preventDefault()
    setPickerActive(!pickerActive)
  }
  return (
    <>
      <nav>
        <a className="button" onClick={(e) => togglePicker(e)}>
          {pickerActive ? 'Farbe wählen' : 'Farbe wählen'}
        </a>
        <a className="button" onClick={(e) => toggleCorners(e)}>
          {withCorners ? 'Stoßschutz entfernen' : 'Stoßschutz hinzufügen'}
        </a>
        <a className="button" onClick={(e) => toggleFeet(e)}>
          {withFeet ? 'Füße entfernen' : 'Füße hinzufügen'}
        </a>
        <a className="button" onClick={(e) => toggleHandle(e)}>
          {withHandle ? 'Griff entfernen' : 'Griff hinzufügen'}
        </a>
      </nav>
      <Canvas concurrent gl={{ alpha: false }} pixelRatio={[1, 2]} camera={{ position: [0, 3, 100], fov: 15 }}>
        <color attach="background" args={['black']} />
        <fog attach="fog" args={['black', 15, 20]} />
        <Suspense fallback={null}>
          <group>
            <TransformControls
              ref={transform}
              mode="rotate"
              position={[0, 0.5, 0]}
              showX={false}
              showY={true}
              showZ={false}
              size={2}
              space="local"
              axis="Y">
              <Applicase state={state}
                scale={[3, 3, 3]}
                position={[0,0,0]}
                withCorners={withCorners}
                withFeet={withFeet}
                withHandle={withHandle}
                pickerActive={pickerActive}
                on />
            </TransformControls>
            <VideoText {...store} position={[0, 1.3, -2]} />
            <Ground />
          </group>
          <Intro start={true} set={setReady} />
          <Environment files="lights_90toLeft.hdr" />
        </Suspense>
      </Canvas>
      <Picker state={state} pickerActive={pickerActive} />
    </>
  )
}
