import React from 'react'
import { proxy, useProxy } from "valtio";

const Body = ({state, materials, nodes, position=[0,0,0],visible=true,...otherProps}) => {
  const snap=useProxy(state);
	return (
    <mesh
      receiveShadow
      castShadow
      visible={visible}
      position={position}
      material={materials["plastic.colors.weiss"]}
      material-color={snap.items.body}
      geometry={nodes.Schale_UT.geometry}
    />
  );
}

export default Body
