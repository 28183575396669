import React from 'react'

const Clasps = ({state,materials,nodes,position=[0,0,0],visible=true, ...otherProps}) => {
	return (
    <group>
      {/* Verschluss rechts */}
      {/** Verschlussabdeckung */}
      <mesh
        receiveShadow
        visible={visible}
        castShadow
        position={position}
        material={materials["plastic.black.rough"]}
        geometry={nodes["PDM-00743001"].geometry}
      />
      {/*Öffner Taste*/}
      <mesh
        receiveShadow
        visible={visible}
        castShadow
        position={position}
        material={materials["plastic.colors.rot"]}
        geometry={nodes["PDM-01002001"].geometry}
      />
      <mesh
        receiveShadow
        visible={visible}
        castShadow
        position={position}
        material={materials.iron}
        geometry={nodes["PDM-01001001"].geometry}
      />
      <mesh
        receiveShadow
        visible={visible}
        castShadow
        position={position}
        material={materials.iron}
        geometry={nodes["PDM-00996001"].geometry}
      />
      {/** Verschluss */}
      <mesh
        receiveShadow
        visible={visible}
        castShadow
        position={position}
        material={materials.iron}
        geometry={nodes["PDM-01000001"].geometry}
      />
      {/** Schlüsselgriff */}
      <mesh
        receiveShadow
        visible={visible}
        castShadow
        position={position}
        material={materials["plastic.black.rough"]}
        geometry={nodes.Cube015.geometry}
      />
      {/* Schlüsselbart */}
      <mesh
        receiveShadow
        visible={visible}
        castShadow
        position={position}
        material={materials.iron}
        geometry={nodes.Cube015_1.geometry}
      />
      <mesh
        receiveShadow
        visible={visible}
        castShadow
        position={position}
        material={materials.chrome}
        geometry={nodes["PDM-00995002"].geometry}
      />
      {/** Verschluss */}
      <mesh
        receiveShadow
        visible={visible}
        castShadow
        position={position}
        material={materials.iron}
        geometry={nodes["PDM-00995003"].geometry}
      />
      {/** Verschluss */}
      <mesh
        receiveShadow
        visible={visible}
        castShadow
        position={position}
        material={materials.iron}
        geometry={nodes["PDM-00997001"].geometry}
      />
      {/** Verschluss */}
      <mesh
        receiveShadow
        visible={visible}
        castShadow
        position={position}
        material={materials["plastic.black.rough"]}
        geometry={nodes["PDM-01004001"].geometry}
      />
      {/** Verschluss */}
      <mesh
        receiveShadow
        visible={visible}
        castShadow
        position={position}
        material={materials["plastic.black.rough"]}
        geometry={nodes["PDM-01003001"].geometry}
      />
      {/** Verschluss */}
      <mesh
        receiveShadow
        visible={visible}
        castShadow
        position={position}
        material={materials["plastic.black.rough"]}
        geometry={nodes["PDM-01005001"].geometry}
      />
      {/** Verschluss */}
      <mesh
        receiveShadow
        visible={visible}
        castShadow
        position={position}
        material={materials["plastic.black.rough"]}
        geometry={nodes["506153_B001"].geometry}
      />
      {/** Verschluss */}
      <mesh
        receiveShadow
        visible={visible}
        castShadow
        position={position}
        material={materials["plastic.black.rough"]}
        geometry={nodes["506153_A001"].geometry}
      />

      {/* Verschluss links */}
      <mesh
        receiveShadow
        castShadow
        position={position}
        visible={visible}
        material={materials["plastic.black.rough"]}
        geometry={nodes["PDM-00743"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        position={position}
        visible={visible}
        material={materials["plastic.colors.rot"]}
        geometry={nodes["PDM-01002"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        position={position}
        visible={visible}
        material={materials.iron}
        geometry={nodes["PDM-01001"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        position={position}
        visible={visible}
        material={materials.iron}
        geometry={nodes["PDM-00996"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        position={position}
        visible={visible}
        material={materials.iron}
        geometry={nodes["PDM-01000"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        position={position}
        visible={visible}
        material={materials["plastic.black.rough"]}
        geometry={nodes.Cube061.geometry}
      />
      <mesh
        receiveShadow
        castShadow
        position={position}
        visible={visible}
        material={materials.iron}
        geometry={nodes.Cube061_1.geometry}
      />
      <mesh
        receiveShadow
        castShadow
        position={position}
        visible={visible}
        material={materials.chrome}
        geometry={nodes["PDM-00995"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        position={position}
        visible={visible}
        material={materials.iron}
        geometry={nodes["PDM-00995001"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        position={position}
        visible={visible}
        material={materials.iron}
        geometry={nodes["PDM-00997"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        position={position}
        visible={visible}
        material={materials["plastic.black.rough"]}
        geometry={nodes["PDM-01004"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        position={position}
        visible={visible}
        material={materials["plastic.black.rough"]}
        geometry={nodes["PDM-01003"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        position={position}
        visible={visible}
        material={materials["plastic.black.rough"]}
        geometry={nodes["PDM-01005"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        position={position}
        visible={visible}
        material={materials["plastic.black.rough"]}
        geometry={nodes["506153_B"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        position={position}
        visible={visible}
        material={materials["plastic.black.rough"]}
        geometry={nodes["506153_A"].geometry}
      />
    </group>
  );
}

export default Clasps