import React from 'react'

const BottomHinges = ({state,materials,nodes,position=[0,0,0],visible=true, ...otherProps}) => {
	return (
    <group>
      <mesh
        receiveShadow
        castShadow
        position={position}
		visible={visible}
        material={materials["black-glossy"]}
        geometry={nodes["PDM-00726"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        position={position}
		visible={visible}
        material={materials["black-glossy"]}
        geometry={nodes["PDM-00725"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        position={position}
		visible={visible}
        material={materials["black-glossy"]}
        geometry={nodes["PDM-00726001"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
        position={position}
		visible={visible}
        material={materials["black-glossy"]}
        geometry={nodes["PDM-00725001"].geometry}
      />
    </group>
  );
}

export default BottomHinges
