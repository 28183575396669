import React from 'react'

const Handle = ({state,materials,nodes,position=[0,0,0],visible=true, ...otherProps}) => {
	return (
    <group>
      {/* Griff außen */}
      <mesh
        receiveShadow
        castShadow
        visible={visible}
        position={[0, 0, 0]}
        material={materials["plastic.black.rough"]}
        geometry={nodes["PDM-00735"].geometry}
      />
      {/* Griff innen */}
      <mesh
        receiveShadow
        castShadow
        visible={visible}
        position={[0, 0, 0]}
        material={materials["plastic.gray.veryrough"]}
        geometry={nodes["PDM-00734"].geometry}
      />
      {/*GriffScharnier*/}
      <mesh
        receiveShadow
        castShadow
        visible={visible}
        position={[0, 0, 0]}
        material={materials["plastic.black.rough"]}
        geometry={nodes["PDM-00736"].geometry}
      />
      {/*GriffScharnierbolzen*/}
      <mesh
        receiveShadow
        castShadow
        visible={visible}
        position={[0, 0, 0]}
        material={materials.chrome}
        geometry={nodes["PDM-00733"].geometry}
      />
      {/*GriffScharnierbolzen*/}
      <mesh
        receiveShadow
        castShadow
        visible={visible}
        position={[0, 0, 0]}
        material={materials.chrome}
        geometry={nodes["PDM-00733001"].geometry}
      />
      {/*GriffScharnier*/}
      <mesh
        receiveShadow
        castShadow
        visible={visible}
        position={[0, 0, 0]}
        material={materials["plastic.black.rough"]}
        geometry={nodes["PDM-00736001"].geometry}
      />
      {/*Scharniernieten*/}
      <mesh
        receiveShadow
        castShadow
        visible={visible}
        position={[0, 0, 0]}
        material={materials.iron}
        geometry={nodes["451190"].geometry}
      />
    </group>
  );
}

export default Handle
