import React from 'react'

const FabricTape = ({state,materials,nodes,position=[0,0,0],visible=true, ...otherProps}) => {
	return (
    <group>
      <mesh
        receiveShadow
        castShadow
		visible={visible}
        position={position}
        material={materials.gewebeband}
        geometry={nodes["PDM-00691"].geometry}
      />
      <mesh
        receiveShadow
        castShadow
		visible={visible}
        position={position}
        material={materials.gewebeband}
        geometry={nodes["PDM-00690"].geometry}
      />
    </group>
  );
}

export default FabricTape
